import { getVariantInfoFromCache } from './VariantsDetails';

export const getVariantVideosFromCache = ({ activeVariantId }) => {
  const cachedVariantData = getVariantInfoFromCache({ activeVariantId });
  if (!cachedVariantData) {
    return;
  }

  const { videos } = cachedVariantData;
  const videosData = Object.values(videos || {});

  // No videos present exit early
  if (videosData.length === 0) {
    return;
  }

  const initialValue = [];

  return videosData.reduce((accumalatedVideos, videoData) => {
    accumalatedVideos.push({
      id: videoData.id,
      url: videoData.url,
      prepared: videoData.prepared,
      error: videoData.error,
      position: videoData.position || undefined
    });
    return accumalatedVideos;
  }, initialValue);
};

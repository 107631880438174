import React, { memo, useEffect, useState, useContext } from 'react';
import { getVariantPicturesFromCache } from 'qs-data-manager/Variants/VariantPictures';
import { ActiveVariantId } from '../../../context';
import CacheListenerCallback from 'qs-helpers/CacheListenerCallback';
import {
  attachVariantInfoListener,
  removeVariantInfoListener
} from 'qs-data-manager/Variants/VariantsDetails';
import VariantImageView from './VariantImageView';
import { getVariantVideosFromCache } from 'qs-data-manager/Variants/VariantVideos';
import { videoThumbnailUrl } from 'qs-services/VideoUpload/UploadVideos/Api';

export default memo(({ containerClasses = '' }) => {
  const { activeVariantId } = useContext(ActiveVariantId);
  const [variantPictures, setVariantPictures] = useState();

  useEffect(() => {
    const cachedPictureData = getVariantPicturesFromCache({ activeVariantId });
    const cachedVideoData = getVariantVideosFromCache({ activeVariantId });
    setVariantPictures([
      ...(cachedPictureData ?? []),
      ...(cachedVideoData ?? []).map(
        video =>
          !!video.id && {
            id: video.id,
            url: videoThumbnailUrl(video.id),
            videoUrl: video.url,
            prepared: video.prepared,
            processed: video.processed,
            error: video.error
          }
      )
    ]);

    const listener = (error, payload) => {
      const { err, loading, refreshing, data } = CacheListenerCallback(error, payload);
      if (err || loading || refreshing || !data) {
        return;
      }
      const cachedPictureData = getVariantPicturesFromCache({ activeVariantId });
      const cachedVideoData = getVariantVideosFromCache({ activeVariantId });
      setVariantPictures([
        ...(cachedPictureData ?? []),
        ...(cachedVideoData ?? []).map(
          video =>
            !!video.id && {
              id: video.id,
              url: videoThumbnailUrl(video.id),
              videoUrl: video.url,
              prepared: video.prepared,
              processed: video.processed,
              error: video.error
            }
        )
      ]);
    };

    attachVariantInfoListener({ listener, variantId: activeVariantId });
    return () => removeVariantInfoListener({ listener, variantId: activeVariantId });
  }, [activeVariantId]);

  return (
    <div className={containerClasses}>
      <VariantImageView pictures={variantPictures} activeVariantId={activeVariantId} />
    </div>
  );
});

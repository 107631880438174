import React, { useState, Fragment, useContext } from 'react';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import toastr from 'toastr';

import { ActiveVariantId } from '../../../../context';
import {
  setVariantPictureAsDefault,
  isCurrentImageDefault,
  deleteSelectedVariantImage
} from 'qs-data-manager/Variants/VariantPictures';
import {
  DialogDarkTheme,
  ButtonDanger,
  SecondaryButton
} from '../../../../../../Common/DarkThemeDialog';

import { ReactComponent as BackIcon } from 'qs-assets/Media/arrow-left.svg';
import { ReactComponent as TrashIcon } from 'qs-assets/Media/trash.svg';
import Loader from '../../../../../../Common/Loader';
import { getI18N } from 'qs-services/i18N';
import './styles.scss';

export default ({
  onClose,
  currentImage,
  updateDefaultImageId,
  showDelete = true,
  showSetAsDefault = true
}) => {
  const { activeVariantId } = useContext(ActiveVariantId);
  const [deleteConfirm, showDeleteConfirm] = useState(false);
  const [showDefaultLoader, setShowDefaultLoader] = useState(false);
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);

  const { t } = getI18N();

  const toggleDeleteClick = event => {
    event.stopPropagation();
    showDeleteConfirm(prevDeleteConfirm => !prevDeleteConfirm);
  };

  const setAsDefaultVariantImage = async event => {
    event.preventDefault();
    event.stopPropagation();
    setShowDefaultLoader(true);
    try {
      await setVariantPictureAsDefault({
        variantId: activeVariantId,
        pictureId: currentImage.id,
        url: currentImage.url
      });
      updateDefaultImageId(currentImage.id);
      toastr.success(t('default_image_updated'));
    } catch (updateDefaultImageError) {
      toastr.error(t('something_went_wrong_while_setting_this_image_as_the_default'));
    }
    setShowDefaultLoader(false);
  };

  const currentImageDefault = isCurrentImageDefault({
    activeVariantId,
    pictureId: currentImage.id
  });

  const onDeleteImage = async event => {
    toggleDeleteClick(event);
    setShowDeleteLoader(true);
    try {
      await deleteSelectedVariantImage({ variantId: activeVariantId, currentImage });
      onClose();
    } catch (deleteImageError) {
      toastr.error(t('something_went_wrong_while_deleting_this_variant_image'));
      setShowDeleteLoader(false);
    }
  };

  const getSetAsDefaultComponent = () => {
    if (!showSetAsDefault) return;

    if (currentImageDefault) {
      return;
    }

    if (showDefaultLoader) {
      return (
        <div className="buttonLinkContainer">
          <Loader size="extraSmall" />
        </div>
      );
    }

    return (
      <div className="buttonLinkContainer">
        <button className="ellipsis buttonLink" onClick={setAsDefaultVariantImage}>
          {t('set_as_default')}
        </button>
      </div>
    );
  };

  const getDeleteComponent = () => {
    if (!showDelete) return;

    if (showDeleteLoader) {
      return <Loader size="extraSmall" />;
    }

    return <TrashIcon onClick={toggleDeleteClick} className={'trashIcon'} />;
  };

  return (
    <Fragment>
      <div className="singleImageViewHeader">
        <BackIcon onClick={onClose} className={'backIcon'} />
        <div className="rightAlignedActions">
          {getSetAsDefaultComponent()}
          {getDeleteComponent()}
        </div>
      </div>
      {deleteConfirm && (
        <DialogDarkTheme open={true}>
          <DialogTitle>{t('delete_variant_image')}</DialogTitle>
          <DialogContent>
            {t('are_you_sure_you_want_to_delete_this_iamge_from_this_variant')}
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={toggleDeleteClick}>{t('cancel')}</SecondaryButton>
            <ButtonDanger color={'primary'} onClick={onDeleteImage}>
              {t('delete')}
            </ButtonDanger>
          </DialogActions>
        </DialogDarkTheme>
      )}
    </Fragment>
  );
};

import React, { Fragment, useRef } from 'react';
import { Modal, withStyles, Backdrop } from '@material-ui/core';
import VideoPlayer from '../../VideoPlayer';
import { hslVideoUrl } from 'qs-services/VideoUpload/UploadVideos/Api';
import Loader from '../Loader';
import ErrorIcon from '../ErrorIcon';

const ImageBackDrop = withStyles({
  root: {
    backgroundColor: 'rgba(0,0,0,0.85)'
  }
})(Backdrop);

export default ({ onClose, videoState, children }) => {
  const productMediaRef = useRef();

  const onProductMediaMount = ref => {
    productMediaRef.current = ref;
  };

  const contentBackdropClick = event => {
    event.preventDefault();
    if (
      event.target.id === 'content-backdrop' ||
      event.target.id === 'image-navigation-container' ||
      event.target.id === 'image-container'
    ) {
      onClose();
    }
  };

  const getPictureData = () => {
    if (videoState.error) {
      return <ErrorIcon width={80} height={80} />;
    }

    if (videoState.prepared) {
      return (
        <Fragment>
          <VideoPlayer
            className="product-video"
            onVideoMount={onProductMediaMount}
            videoId={videoState.id}
            videoUrl={hslVideoUrl(videoState.id)}
            seekTo
            withAudio
            controls
          />
        </Fragment>
      );
    }
    return <Loader size={'large'} />;
  };

  const getVideoGalleryView = () => {
    return (
      <div id="content-backdrop" className="singleImageContent" onClick={contentBackdropClick}>
        {children}
        <div className="productsImageNavigation" id="image-navigation-container">
          <div className="imageParentContainer" id="image-container">
            {getPictureData()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      BackdropComponent={ImageBackDrop}
      className="imageFullViewContainer"
    >
      {getVideoGalleryView()}
    </Modal>
  );
};

import React, { useEffect, useState, Fragment, useCallback, useRef, useContext } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { withStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import toastr from 'toastr';

import { convertImageUrlSize, toggleGlobalLoader } from 'qs-helpers';
import usePictureRetryHook from 'qs-hooks/usePictureRetryHook';
import { getActiveCatalogueId } from 'qs-data-manager/Catalogues';
import { ActiveVariantId } from '../../../../context';
import { uploadPicturesToProduct } from 'qs-data-manager/Products';
import { IMAGE_UPLOAD_HELPER } from 'qs-helpers/ProcessUploadedImage';
import { IMAGE_LOADER, IMAGE_ERROR, IMAGE_HIDE_LOADER } from 'qs-helpers/PicturesProcessingHelper';
import { filterImagesFromFiles } from 'qs-helpers/FileUploads/ProcessUploadedFiles';
import {
  getDefaultPictureIdOfVariantFromCache,
  getVariantInfoFromCache,
  setVariantInfoInCache
} from 'qs-data-manager/Variants/VariantsDetails';
import arrayMove from 'array-move';
import { sortPictures } from 'qs-api/Products/api';
import {
  getVariantsMetaDataFromCache,
  setVariantsMetaDataInCache
} from 'qs-data-manager/Variants/FetchVariants';

import ImageGalleryView from '../../../../../../Common/ImageGalleryView';
import ErrorPopup from '../../../../../../Common/ErrorPopup';
import Loader from '../../../../../../Common/Loader';
import AddImagePopup from '../AddImagePopup';
import ImageActions from '../ImageActions';
import { ReactComponent as ErrorIcon } from 'qs-assets/Media/image-error.svg';
import { ReactComponent as UploadIcon } from 'qs-assets/Media/plus.svg';
import './styles.scss';
import { getI18N } from 'qs-services/i18N';
import VideoGalleryView from '../../../../../../Common/VideoGalleryView';

const defaultPictures = [];
const SmallFab = withStyles({
  root: {
    width: 'inherit',
    height: 'inherit',
    minHeight: 'unset'
  }
})(Fab);

const sortPicturesByPosition = ({ pictures, defaultPictureId }) => {
  if (!Array.isArray(pictures) || pictures.length < 2) {
    return pictures;
  }

  const positionedPictures = pictures.sort(
    (a, b) => (a.position || undefined) - (b.position || undefined)
  );

  if (defaultPictureId && positionedPictures[0].id !== defaultPictureId) {
    const pos = pictures.findIndex(picture => picture.id === defaultPictureId);
    if (pos !== -1) {
      positionedPictures.splice(0, 0, positionedPictures.splice(pos, 1)[0]);
    }
  }

  return positionedPictures;
};

const SortableItem = SortableElement(({ value, getImageUiBasedOnState }) =>
  getImageUiBasedOnState(value)
);

const SortableList = SortableContainer(
  ({ items, getImageUiBasedOnState, disableRearrangeForPicture }) => (
    <div className="varinatPictureSortableList">
      {items.map((value, index) => {
        return (
          <SortableItem
            key={`item-${value.id}`}
            index={index}
            value={value}
            getImageUiBasedOnState={getImageUiBasedOnState}
            disabled={value.hasOwnProperty('videoUrl') || disableRearrangeForPicture(value)}
          />
        );
      })}
    </div>
  )
);

export default ({ pictures = defaultPictures }) => {
  const [
    picturesLoadState,
    handleImageLoad,
    handleImageError,
    updateTimeoutRefForPictures
  ] = usePictureRetryHook();

  const { activeVariantId } = useContext(ActiveVariantId);
  const uploadIcon = useRef(null);
  const pictureViewContainer = useRef(null);

  const [selectedPicture, setPictureToShow] = useState();
  const [selectedVideo, setVideoToShow] = useState();
  const [uploadedImages, setUploadedImages] = useState();
  const [showFloatingButton, setShowFloatingButton] = useState(false);
  const [noImageFilesPresent, setNoImageFilesPresent] = useState(false);
  const [defaultPictureId, setDefaultPictureId] = useState(
    getDefaultPictureIdOfVariantFromCache({ activeVariantId })
  );
  const [positionedPictures, setPositionedPictures] = useState(
    sortPicturesByPosition({ pictures, defaultPictureId })
  );
  const pickerRef = useRef(null);
  const floatingPickerRef = useRef(null);

  const { t } = getI18N();

  useEffect(() => {
    updateTimeoutRefForPictures(pictures);
  }, [pictures, updateTimeoutRefForPictures]);

  useEffect(() => {
    setDefaultPictureId(getDefaultPictureIdOfVariantFromCache({ activeVariantId }));
  }, [activeVariantId]);

  useEffect(() => {
    setPositionedPictures(sortPicturesByPosition({ pictures, defaultPictureId }));
  }, [pictures, defaultPictureId]);

  useEffect(() => {
    const options = {
      root: pictureViewContainer.current,
      rootMargin: '0px',
      threshold: 0.3
    };
    const uploadIconNode = uploadIcon.current;

    const handleVisibility = entries => {
      if (!Array.isArray(entries)) {
        return;
      }

      if (entries[0].intersectionRatio > options.threshold) {
        setShowFloatingButton(false);
        return;
      }
      setShowFloatingButton(true);
    };

    const observer = new IntersectionObserver(handleVisibility, options);
    observer.observe(uploadIconNode);

    return () => {
      observer.unobserve(uploadIconNode);
    };
  }, []);

  const onUpload = async event => {
    event.stopPropagation();
    event.preventDefault();
    const images = filterImagesFromFiles(event.target.files);

    // Important. This should be done once the files have been processed.
    // If nullified before that, then the files in the event are lost
    if (floatingPickerRef.current) {
      floatingPickerRef.current.value = null;
    }
    pickerRef.current.value = null;

    if (images.length > 0) {
      setUploadedImages(images);
      return;
    }

    setNoImageFilesPresent(true);
  };

  const startPicturesUpload = useCallback(
    async selectedOptionId => {
      const savedUploadedImages = uploadedImages;
      //clear out the save values
      setUploadedImages();
      const loaderKey = `uploadPicturesToVariant${Date.now()}`;
      toggleGlobalLoader(loaderKey, true);
      try {
        await uploadPicturesToProduct({
          images: savedUploadedImages,
          productId: activeVariantId,
          catalogueId: getActiveCatalogueId(),
          uploadData: {
            key: IMAGE_UPLOAD_HELPER.VARIANT_PICTURE_UPLOAD.key,
            optionId: selectedOptionId
          }
        });
      } catch (uploadError) {
        toastr.error(t('something_went_wrong_while_uploading_pictures_to_the_product'));
      } finally {
        toggleGlobalLoader(loaderKey, false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUploadedImages, uploadedImages, activeVariantId]
  );

  const loadThisImage = pictureState => {
    setPictureToShow(pictureState);
  };

  const closeSingleImageView = useCallback(() => {
    setPictureToShow();
  }, []);

  const loadThisVideo = videoState => {
    setVideoToShow(videoState);
  };

  const closeSingleVideoView = useCallback(() => {
    setVideoToShow();
  }, []);

  const updateDefaultImageId = id => {
    const oldIndex = positionedPictures.findIndex(({ id: pictureId }) => id === pictureId);
    const sortedPictures = arrayMove(positionedPictures, oldIndex, 0);
    setPositionedPictures(sortedPictures);
  };

  const disableRearrangeForPicture = useCallback(
    pictureState => {
      if (!picturesLoadState || !picturesLoadState[pictureState.id]) {
        return true;
      }
      const { state } = picturesLoadState[pictureState.id] || {};
      return state === IMAGE_LOADER;
    },
    [picturesLoadState]
  );

  const getImageUiBasedOnState = pictureState => {
    const { state = IMAGE_LOADER, retryCount = 0 } = picturesLoadState[pictureState.id] || {};
    let variantPictureClasses = 'variantPictureContainer';
    if (pictureState.id === positionedPictures[0].id) {
      variantPictureClasses += ` variantDefaultImage`;
    }

    if ((pictureState.error && !pictureState.prepared) || state === IMAGE_ERROR) {
      variantPictureClasses += ' pictureError';
      return (
        <div
          key={`${activeVariantId}-${pictureState.id}`}
          className={variantPictureClasses}
          onClick={() => loadThisImage(pictureState)}
        >
          <ErrorIcon className={'errorIcon'} />
        </div>
      );
    }

    const url = convertImageUrlSize({ size: '200', url: pictureState.url });
    return (
      <Fragment>
        {pictureState.hasOwnProperty('videoUrl') && <div className="picturesDivider" />}
        <div
          key={`${activeVariantId}-${pictureState.id}`}
          className={variantPictureClasses}
          onClick={() =>
            pictureState.hasOwnProperty('videoUrl')
              ? loadThisVideo(pictureState)
              : loadThisImage(pictureState)
          }
        >
          {state === IMAGE_LOADER && (
            <div className={`pictureLoader`}>
              <Loader size={'small'} />
            </div>
          )}
          {pictureState.prepared && (
            <Fragment>
              <div
                key={`${activeVariantId}-${pictureState.id}-background-${retryCount}`}
                className={`picture picturesAspectRatio`}
                style={{ backgroundImage: `url(${url})` }}
              />
              {pictureState.videoUrl && <span className="playIcon" />}
              <img
                key={`${activeVariantId}-${pictureState.id}-${retryCount}`}
                onError={handleImageError(pictureState.id, IMAGE_ERROR)}
                onLoad={handleImageLoad(pictureState.id, IMAGE_HIDE_LOADER)}
                src={url}
                className={'previewImage'}
                alt={''}
              />
            </Fragment>
          )}
        </div>
        {pictureState.hasOwnProperty('videoUrl') && <div className="picturesDivider" />}
      </Fragment>
    );
  };

  const onSortEnd = useCallback(
    async ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return;
      }
      const key = `positioningPicturePositions${Date.now()}`;
      toggleGlobalLoader(key, true);
      const updatedPictures = arrayMove(positionedPictures, oldIndex, newIndex);
      const pictureIds = [];
      const temp = getVariantInfoFromCache({ activeVariantId });
      const picturesDataFromCachedVariantData = temp.pictures;
      const updatedPicturesData = {};
      updatedPictures.forEach((picture, index) => {
        pictureIds.push(picture.id);
        updatedPicturesData[picture.id] = {
          ...picturesDataFromCachedVariantData[picture.id],
          position: index + 1
        };
      });
      setPositionedPictures(updatedPictures);
      try {
        await sortPictures({
          productId: activeVariantId,
          pictureIds
        });
        const updates = {
          pictures: updatedPicturesData
        };
        if (oldIndex === 0 || newIndex === 0) {
          updates.pictureUrl = updatedPictures[0].url;
          updates.default_picture_id = updatedPictures[0].id;
          const metaData = getVariantsMetaDataFromCache(activeVariantId);
          metaData.pictureUrl = updatedPictures[0].url;
          setVariantsMetaDataInCache({ variantId: activeVariantId, metaData });
          setDefaultPictureId(updatedPictures[0].id);
        }
        setVariantInfoInCache({
          activeVariantId,
          updates
        });
      } catch (error) {
        setPositionedPictures(positionedPictures);
        toastr.error(t('failed_to_update_position'));
      } finally {
        toggleGlobalLoader(key, false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [positionedPictures, activeVariantId]
  );

  return (
    <Fragment>
      <div className={'variantPictureViewContainer'} ref={pictureViewContainer}>
        <div className={'varinatPictureSortableList'}>
          <SortableList
            axis={'x'}
            lockAxis={'x'}
            items={positionedPictures}
            distance={1}
            onSortEnd={onSortEnd}
            getImageUiBasedOnState={getImageUiBasedOnState}
            disableRearrangeForPicture={disableRearrangeForPicture}
            shouldCancelStart={() => false}
          />
        </div>
        <div className={'variantPictureContainer variantImageUploader'} ref={uploadIcon}>
          <UploadIcon className={'uploadIcon'} />
          <input
            ref={pickerRef}
            type="file"
            name="VariantPictures"
            accept="image/*"
            multiple
            onChange={onUpload}
            className={'filePicker'}
          />
        </div>
        {showFloatingButton && (
          <div className={'floatingUploadContainer'}>
            <SmallFab color="primary" aria-label="add">
              <input
                ref={floatingPickerRef}
                type="file"
                name="VariantPictures"
                accept="image/*"
                multiple
                onChange={onUpload}
                className={'filePicker'}
              />
              <UploadIcon className={'floatingAddIcon'} />
            </SmallFab>
          </div>
        )}
      </div>
      {selectedPicture && (
        <ImageGalleryView onClose={closeSingleImageView} picturesData={[selectedPicture]}>
          <ImageActions
            onClose={closeSingleImageView}
            currentImage={selectedPicture}
            updateDefaultImageId={updateDefaultImageId}
          />
        </ImageGalleryView>
      )}
      {selectedVideo && (
        <VideoGalleryView onClose={closeSingleVideoView} videoState={selectedVideo}>
          <ImageActions
            onClose={closeSingleVideoView}
            currentImage={selectedVideo}
            showDelete={false}
            showSetAsDefault={false}
          />
        </VideoGalleryView>
      )}
      {uploadedImages && (
        <AddImagePopup
          uploadedImages={uploadedImages}
          onUpload={startPicturesUpload}
          onClose={setUploadedImages}
        />
      )}
      {noImageFilesPresent && (
        <ErrorPopup
          showContactSupport={false}
          onClose={() => setNoImageFilesPresent(false)}
          title="Upload Images"
        >
          No image files found
        </ErrorPopup>
      )}
    </Fragment>
  );
};
